<template lang="pug">
.layout-blank
  div
    slot
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      return route.meta.layout || "Default";
    });

    return { layout };
  },
};
</script>

<style lang="scss" scoped>
span {
  @apply inline-block;
  @apply h-12;
  @apply w-12;
  @apply rounded-full;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply transition-all;
  @apply cursor-pointer;
  i {
    @apply text-4xl;
    @apply text-gignetgreen-500;
  }
  &:hover {
    @apply bg-white;
    i {
      @apply text-gignetgreen-400;
    }
  }
  &:focus {
    @apply bg-none;
  }
}

.layout-blank {
  @apply p-5;
  @apply h-full;
}

.fade-enter-active {
  transition: opacity 0.7s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
